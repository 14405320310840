import { css, type Theme } from "@emotion/react";

import { colors, helpers, utilities, dimensions } from "@styles";

export const form = (theme: Theme) => css`
  display: inline-block;
  border-radius: 4px;
  border: 1px solid ${colors.grey.lightFamily.dark};
  cursor: pointer;
  ${utilities.transition}

  &:hover {
    box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(theme.text.body, 0.25)};
    border: 1px solid ${theme.system.borderHover};
  }

  &:active {
    border: 1px solid ${theme.system.borderActive};
  }
`;

export const formDisabled = css`
  background-color: ${colors.grey.lightFamily.light};
  border-color: ${colors.grey.lightFamily.dark};
  cursor: not-allowed;

  &:hover {
    box-shadow: none;
    border-color: ${colors.grey.lightFamily.dark};
  }

  &:active {
    background-color: ${colors.grey.lightFamily.light};
  }
`;

export const GoogleSSOButton = css`
  display: flex;
  align-content: center;
  width: 250px;
  padding: 0.5625rem 0.3125rem 0.5625rem 0.5625rem;
  cursor: pointer;
  justify-content: center;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: normal;
  }
`;

export const googleSSOButtonDS4 = (theme: Theme) => css`
  background-color: ${theme.text.onBackground};
  border-radius: ${dimensions.borderRadius};
`;

export const submitDS4 = (theme: Theme) => css`
  background: ${theme.text.onBackground};
  border-radius: ${dimensions.borderRadius};
  color: ${theme.surface.overlayDark};
`;

export const continueInBrowserMessage = css`
  width: 250px;
  text-align: left;
  margin: 1rem auto 0 auto;
`;

export const submit = css`
  font-family: "Roboto", sans-serif;
  background: ${colors.white};
  border: none;
  font-size: 1rem;
  color: ${colors.black}; // TODO: Update with Aviary accent color - BLACK
  cursor: pointer;
  padding-bottom: 0;
`;

export const submitDisabled = css`
  color: ${colors.grey.darkFamily.light};
  background-color: ${colors.grey.lightFamily.light};
  cursor: not-allowed;
`;

export const logo = css`
  width: 1.3125rem;
  height: 1.3125rem;
  margin-right: 0.1rem;
`;

export const disabled = css`
  cursor: not-allowed;
`;

export const orSection = css`
  margin: 1rem 0;
  color: ${colors.grey.darkFamily.light};
  overflow: hidden;
  text-transform: uppercase;

  &:before,
  &:after {
    background-color: ${colors.grey.lightFamily.darker};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 25%;
  }

  &:before {
    right: 0.5rem;
    margin-left: -50%;
  }

  &:after {
    left: 0.5rem;
    margin-right: -50%;
  }
`;

export const buttonWrapper = css`
  text-align: center;
  width: 100%;
`;
